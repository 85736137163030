import { api } from "@/lib/axios/axiosConfigs";
import { defineCancelApiObject } from "@/lib/axios/axiosUtils";
import { ENDPOINTS } from "@/constants/endpoints";

export const healerServices = {
  getHealers: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALERS}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getHealers.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  existUserUpdateKey: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.EXIST_USER_UPDATE_KEY}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.existUserUpdateKey.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getSitemapHealers: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_SITEMAP_HEALERS}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getSitemapHealers.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getSitemapCourses: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_SITEMAP_COURSES}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getSitemapCourses.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getSitemapBlogs: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_SITEMAP_BLOGS}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getSitemapBlogs.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  sendLink: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_SEND_LINK}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.sendLink.name].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  // healerClassLink: async function (params, cancel = false) {
  //   const response = await api.request({
  //     url: `${ENDPOINTS.HEALER_CLASS_LINK}`,
  //     method: "POST",
  //     data: params,
  //     // retrieving the signal value by using the property name
  //     signal: cancel
  //       ? cancelApiObject[this.healerClassLink.name].handleRequestCancellation()
  //           .signal
  //       : undefined,
  //   });

  //   // returning the data
  //   return response.data;
  // },
  healerClassLink: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_LESSON_SEND_LINK}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.healerClassLink.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getBookingTypes: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_BOOKING_TYPES}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getBookingTypes.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  // getTypesOfBooking: async function (params, cancel = false) {
  //   const response = await api.request({
  //     url: `${ENDPOINTS.GET_BOOKING_TYPES}`,
  //     method: "GET",
  //     // retrieving the signal value by using the property name
  //     signal: cancel
  //       ? cancelApiObject[
  //         this.getTypesOfBooking.name
  //       ].handleRequestCancellation().signal
  //       : undefined,
  //   });

  //   // returning the data
  //   return response.data;
  // },

  getModalities: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_MODALITIES}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getModalities.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getSymptoms: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_SYMPTOMS}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getSymptoms.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getModalitiesSearch: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.MODALITY_SEARCH}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: params?.signal
        ? cancelApiObject[this.getModalities.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getSymptomsSearch: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.SYMPTOMS_SEARCH}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: params?.signal
        ? cancelApiObject[this.getSymptoms.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getLanguages: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_LANGUAGES}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getLanguages.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  getCities: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_CITIES}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getCities.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerChangePassword: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_CHANGE_PASSWORD}/${params.id}`,
      method: "PUT",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.healerChangePassword.name].handleRequestCancellation()
            .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealer: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getHealer.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  getPerticularHealerCourses: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PERTICULAR_HEALER_COURSES}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getPerticularHealerCourses.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerDetails: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALER_DETAILS}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getBusinessDetails.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerCourses: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALER_COURSES}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerCourses.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerCourseDetails: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALER_COURSES_DETAILS}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerCourseDetails.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getTypesOfSessions: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_TYPES_OF_BOOKING}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getTypesOfBooking.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateHealer: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.HEALER}/${params.id}`,
      method: "POST",
      data: params.formData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateHealer.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateModalities: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_UPDATE_MODALITIES}/${params.id}`,
      method: "PUT",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateModalities.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateSymptoms: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_UPDATE_SYMPTOMS}/${params.id}`,
      method: "PUT",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateSymptoms.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateBookingTypeAndLanguages: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_UPDATE_BOOKING_TYPES_AND_LANGUAGES}/${params.id}`,
      method: "PUT",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateBookingTypeAndLanguages.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  fetchHealerBankDetails: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_BANK_DETAILS}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.fetchHealerBankDetails.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  healerUpdateBankDetail: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_BANK_DETAILS_UPDATE}/${params.id}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: params.formData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.healerUpdateBankDetail.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  healerbankdetaildelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_BANK_DETAILS}/${params.id}`,
      method: "DELETE",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.healerbankdetaildelete.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerAddBankDetail: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_BANK_DETAILS_CREATE}`,
      method: "POST",
      headers: {
        "Content-Type": "multipart/form-data",
      },
      data: params.formData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.healerAddBankDetail.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  addOrUpdateBusinessDetails: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.ADD_UPDATE_BUSINESS_DETAILS}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.addOrUpdateBusinessDetails.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getBusinessDetails: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_BUSINESS_DETAILS}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getBusinessDetails.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getCountries: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_COUNTRIES}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getCountries.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getStates: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_STATES}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getStates.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getCities: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_CITIES}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getCities.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerExperience: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALER_EXPERIENCE}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerExperience.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  addHealerExperience: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.ADD_HEALER_EXPERIENCE}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.addHealerExperience.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateHealerExperience: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_EXPERIENCE_UPDATE}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateHealerExperience.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  editHealerExperience: async function (id, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_EXPERIENCE_EDIT}/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.editHealerExperience.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  deleteHealerExperience: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DELETE_HEALER_EXPERIENCE}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.deleteHealerExperience.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerAppointment: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALER_APPOINTMENT}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerAppointment.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  addHealerAppointment: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.ADD_HEALER_APPOINTMENT}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.addHealerAppointment.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  editHealerAppointment: async function (id, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.EDIT_HEALER_APPOINTMENT}/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.editHealerAppointment.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateHealerAppointment: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.UPDATE_HEALER_APPOINTMENT}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateHealerAppointment.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  deleteHealerAppointment: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DELETE_HEALER_APPOINTMENT}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.deleteHealerAppointment.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerClassesList: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALER_CLASSES_LIST}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerClassesList.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerUpcomingClassesList: async function (params, page, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALER_CLASSES_UPCOMING_LIST}/${params.id}?page=${page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerUpcomingClassesList.name
        ].handleRequestCancellation().signal
        : undefined,
    });
    // returning the data
    return response.data;
  },
  getHealerPastClassesList: async function (params, page, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALER_CLASSES_PAST_LIST}/${params.id}?page=${page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerPastClassesList.name
        ].handleRequestCancellation().signal
        : undefined,
    });
    // returning the data
    return response.data;
  },

  getHealerClassesDetails: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALER_CLASSES_DETAILS}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerClassesDetails.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerHolidays: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_HOLIDAYS}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerHolidays.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateHealerHolidays: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_HOLIDAY_MARK}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateHealerHolidays.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  deleteHealerHoliday: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DELETE_HEALER_HOLIDAY}/${params.id}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.deleteHealerHoliday.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  getEducationTypes: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.EDUCATION_TYPES}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getEducationTypes.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  addEducation: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_ADD_EDUCATION}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.addEducation.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  editEducation: async function (id, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_EDIT_EDUCATION}/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.editEducation.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateEducation: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_EDUCATION_UPDATE}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateEducation.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  deleteEducation: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_EDUCATION_DELETE}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.deleteEducation.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerEducations: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_EDUCATION_LIST}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerEducations.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  addCertification: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.HEALER_ADD_CERTIFICATION}/${params.id}`,
      method: "POST",
      data: params.formData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.addCertification.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateCertification: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.HEALER_UPDATE_CERTIFICATION}/${params.id}`,
      method: "POST",
      data: params.formData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateCertification.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  editHealerCertifications: async function (id, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_EDIT_CERTIFICATION}/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.editHealerCertifications.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerCertifications: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_CERTIFICATION_LIST}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerCertifications.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  deleteCertification: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_CERTIFICATION_DELETE}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.deleteCertification.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  addAward: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.HEALER_ADD_AWARD}/${params.id}`,
      method: "POST",
      data: params.formData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.addAward.name].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerAwards: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_AWARD_LIST}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getHealerAwards.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  editAward: async function (id, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.HEALER_EDIT_AWARD}/${id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.editAward.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateAward: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.HEALER_UPDATE_AWARD}/${params.id}`,
      method: "POST",
      data: params.formData,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateAward.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  deleteAward: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_AWARD_DELETE}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.deleteAward.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  downloadFile: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_DOWNLOAD_FILE}`,
      method: "POST",
      data: params,
      responseType: "blob",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.downloadFile.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response;
  },
  addHealerClass: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_ADD_CLASS}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.addHealerClass.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  getHealerClassDetail: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_HEALER_CLASSES_DETAILS}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerClassDetail.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateHealerClass: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_UPDATE_CLASS}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateHealerClass.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateHealerClassStatus: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_CLASSES_STATUS_UPDATE}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateHealerClassStatus.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  updateHealerAppointmentStatus: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_APPOINTMENT_STATUS_UPDATE}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.uupdateHealerAppointmentStatus.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  getCategoryTypes: async function (cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_CATEGORY_TYPE}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getCategoryTypes.name
        ].handleRequestCancellation().signal
        : undefined,
    });
    // returning the data
    return response.data;
  },
  getClassDetail: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_CLASSES_DETAILS}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getClassDetail.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  HealerClassDetailDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_CLASS_DELETE}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.HealerClassDetailDelete.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getSubcriptionPlans: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.SUBSCRIPTION_PLANS}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getSubcriptionPlans.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getSubcription: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_SUBSCRIPTION}/${params.id}/${params.type}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getSubcription.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  getHealerAllReviews: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_ALL_REVIEWS}/${params.id}?page=${params.page}`,
      method: "GET",
      data: params.page,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerAllReviews.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  generateHealerSubscription: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_SUBSCRIBE_PLAN}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.generateHealerSubscription.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateSubscriptionPayment: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.UPDATE_SUBSCRIPTION_PAYMENT}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateSubscriptionPayment.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  contactus: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.CONTACT_US}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.contactUs.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getPastHealerBooking: async function (params, page, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_BOOKING_PAST_HISTORY}/${params.id}?page=${page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getPastHealerBooking.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  getUpcomingHealerBooking: async function (params, page, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_BOOKING_UPCOMING_HISTORY}/${params.id}?page=${page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getUpcomingHealerBooking.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },
  // gethealerbooking: async function (params, cancel = false) {
  //   const response = await api.request({
  //     url: `${ENDPOINTS.HEALER_BOOKING_HISTORY}/${params.id}`,
  //     method: "GET",
  //     data: params,
  //     // retrieving the signal value by using the property name
  //     signal: cancel
  //       ? cancelApiObject[
  //           this.gethealerbooking.name
  //         ].handleRequestCancellation().signal
  //       : undefined,
  //   });

  //   // returning the data
  //   return response.data;
  // },

  gethealerbookingDetail: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_BOOKING_DETAIL}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.gethealerbookingDetail.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getPaymentStatus: async function (params, page, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_PAYMENT_STATUS}/${params.id}?page=${page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getPaymentStatus.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getSubscriptionPayment: async function (params, page, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_SUBSCRIPTION_PAYMENT}/${params.id}?page=${page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getSubscriptionPayment.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getTimeAvailability: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_TIME_AVAILABILITY}/${params.id}`,
      method: "GET",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getTimeAvailability.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateAvailability: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_UPDATE_TIME_AVAILABILITY}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.updateAvailability.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateInterval: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_UPDATE_INTERVAL}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateInterval.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  updateBookingInterval: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_UPDATE_BOOKING_INTERVAL}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.updateBookingInterval.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getBlogs: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_SLIDER_INFO}/${params.id}?page=${params.page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getBlogs.name].handleRequestCancellation().signal
        : undefined,
    });

    return response.data;
  },

  getBlogsData: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_SLIDER_INFO}?page=${params.page}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getBlogsData.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    return response.data;
  },

  getBlogDetail: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.GET_BLOG_DETAIL}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getBlogDetail.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerAppointments: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_APPOINTMENT_DATA}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerAppointments.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerAvailableSlots: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_AVAILABLE_SLOTS}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerAvailableSlots.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerAvailableDates: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_AVAILABLE_DATES}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerAvailableDates.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerDashboardDetail: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_DASHBOARD_DETAIL}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerDashboardDetail.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerSalesOverview: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_SALES_OVERVIEW}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerSalesOverview.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerUpcomingAppointment: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_UPCOMING_APPOINTMENT}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerUpcomingAppointment.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerUpcomingApplicants: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_UPCOMING_APPLICANTS}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerUpcomingApplicants.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerApplicantDetail: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_APPLICANT_DETAIL}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerApplicantDetail.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  paymentReminder: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PAYMENT_REMINDER}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.paymentReminder.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  reviewReminder: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.REVIEW_REMINDER}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.reviewReminder.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerAllPaymentReminder: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_ALL_PAYMENT_REMINDER}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.healerAllPaymentReminder.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerPaymentInvoice: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_PAYMENT_INVOICE}/${params}`,
      method: "GET",
      data: params,
      // responseType: "blob",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.healerPaymentInvoice.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerDownloadFile: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_DOWNLOAD_INVOICE}/${params}`,
      method: "GET",
      data: params,
      //responseType: "blob",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.healerDownloadFile.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  subscriptionNotice: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_SUBSCRIPTION_NOTICE}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.subscriptionNotice.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getPrivacyPolicy: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PRIVACY_POLICY}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getPrivacyPolicy.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getTermsAndConditions: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.TERMS_AND_CONDITIONS}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getTermsAndConditions.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getMetaInformation: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.META_INFORMATION}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getMetaInformation.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerSlotsMetaData: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALERSLOTS_METADATA}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.getHealerSlotsMetaData.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  subscriptionRenew: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_SUBSCRIPTIION_RENEW}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.subscriptionRenew.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerTimezone: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_TIMEZONE}/${params.id}`,
      method: "PUT",
      data: params.timezone,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.healerTimezone.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerTimeUnavailability: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_TIME_UNAVAILABILITY}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.healerTimeUnavailability.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  deleteHealerTimeUnavailability: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.DELETE_HEALER_TIME_UNAVAILABILITY}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.deleteHealerTimeUnavailability.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  paymentFees: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.PAYMENT_CHARGES}`,
      method: "POST",
      data:params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.paymentFees.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  storeHealerImages: async function (params, cancel = false) {
    const response = await api.request({
      headers: {
        "Content-Type": "multipart/form-data",
      },
      url: `${ENDPOINTS.HEALER_IMAGES_STORE}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.storeHealerImages.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  deleteHealerImages: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_IMAGES_DELETE}/${params}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[
          this.deleteHealerImages.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  addHealerNotes: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.ADD_HEALER_NOTES}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: params.signal
        ? cancelApiObject[
          this.addHealerNotes.name
        ].handleRequestCancellation().signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  getHealerNotes: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_NOTES_LIST}/${params?.healer_id}/${params?.customer_id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.getHealerNotes.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerNotesEdit: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_NOTES_EDIT}/${params?.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel
        ? cancelApiObject[this.healerNotesEdit.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerNotesUpdate: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_NOTES_UPDATE}/${params.id}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: params.signal
        ? cancelApiObject[this.healerNotesUpdate.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerNotesDelete: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_NOTES_DELETE}/${params.id}`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: params.signal
        ? cancelApiObject[this.healerNotesDelete.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerStripeCustomerId: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_STRIPE_CUSTOMER_ID_STORE}`,
      method: "POST",
      data: params,
      // retrieving the signal value by using the property name
      signal: params.signal
        ? cancelApiObject[this.healerStripeCustomerId.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

  healerSubscriptionCancel: async function (params, cancel = false) {
    const response = await api.request({
      url: `${ENDPOINTS.HEALER_SUBSCRIPTION_CANCEL}`,
      method: "POST",
      data: params?.data,
      // retrieving the signal value by using the property name
      signal: params?.signal
        ? cancelApiObject[this.healerSubscriptionCancel.name].handleRequestCancellation()
          .signal
        : undefined,
    });

    // returning the data
    return response.data;
  },

};

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(healerServices);
